/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Footer from "./footer"
import Header from "./header"
import "./layout.scss"
import {device} from "../queries"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import "./../static/fonts/fonts.css"


const Container = styled.div`
  padding-top: 117px;
  @media ${device.tablet}{
    padding-top: 63px;
  }
`


const Section = ({ children, className }) => {


  return (

    <>
    <Container>
      
      <div>
        <main>{children}</main>
      </div>
    </Container>
      <Footer>

      </Footer>
    </>
  )
}

const Layout = styled(Section)`
  background-repeat: repeat;
  background-size: auto;
  opacity: 0.4;
`




export default Layout


